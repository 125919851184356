import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const PageTitle = ({ title }) => (
  <Helmet>
    <title>{title}</title>
  </Helmet>
);

const App = () => {
  const isWidget = new URLSearchParams(window.location.search).get('widget') === 'true';
  const isMobile = window.innerWidth <= 768;

  return (
    <Router>
      <div
        className={`${isWidget ? 'widget-mode' : 'min-h-screen'} bg-background text-white px-4 py-8 flex items-center justify-center`}
        style={{
          maxWidth: '100%',
          overflowX: 'hidden',
          padding: isMobile ? '16px' : '32px',
        }}
      >
        <PageTitle title="Tekoälyapuri | Sekasin-chat" />
        <div className="flex flex-col items-center justify-center text-center max-w-lg">
          <p className="mb-6">
            Moikka moi, botti on muuttanut uuteen kotiin. Käy tsekkaamassa uudistunut Tekoälyapuri
            osoitteessa sekasin.fi.
          </p>
          <a
            href="https://sekasin.fi"
            className="bg-primary hover:bg-primary-dark text-black py-2 px-4 rounded inline-flex items-center gap-2"
          >
            <span>Siirry sekasin.fi-sivustolle</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
              <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
            </svg>
          </a>
        </div>
      </div>
    </Router>
  );
};

export default App;
