import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import * as Sentry from '@sentry/react';
import ReactGA from 'react-ga4';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { sanitizeSentryEvent } from './utils/sentryUtils';

// Use Analytics only in production and when user has accepted cookies
const useAnalytics =
  process.env.REACT_APP_ENV === 'production' && localStorage.getItem('useAnalytics') === 'true';

// Use Sentry in production and development
const useSentry =
  process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'development';

// GA Configuration object - defined once to maintain consistency
const gaConfig = {
  gaOptions: {
    anonymize_ip: true,
    allowAdFeatures: false,
    cookieFlags: 'SameSite=Strict;Secure',
    storage: 'none',
    storeGac: false,
  },
  gtagOptions: {
    client_storage: 'none',
    anonymize_ip: true,
    allow_google_signals: false,
    allow_ad_personalization_signals: false,
    restricted_data_processing: true,
  },
};

// Initialize GA once at the top level
if (useAnalytics) {
  ReactGA.initialize('G-8R53D2BV73', gaConfig);
}

if (useSentry) {
  Sentry.init({
    dsn: 'https://4630e0850250f045d2ca6e8d1659879e@o4508160430833664.ingest.de.sentry.io/4508160433061968',
    environment: process.env.REACT_APP_ENV || 'production',
    debug: false,

    // Disable IP address collection
    sendDefaultPii: false,
    ipHeaders: [],
    maxIPsInAllowlist: 0,

    integrations: [
      Sentry.browserTracingIntegration({
        tracePropagationTargets: [
          /^https:\/\/qss7df48sc\.execute-api\.eu-north-1\.amazonaws\.com\/production\/api/,
          'localhost:4000',
          ...(process.env.REACT_APP_ENV === 'local' ? ['localhost'] : []),
        ],
      }),
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
      }),
      Sentry.httpClientIntegration(),
      Sentry.breadcrumbsIntegration(),
      Sentry.globalHandlersIntegration({
        onerror: true,
        onunhandledrejection: true,
      }),
    ],

    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,

    beforeSend(event) {
      // First apply existing IP address sanitization
      if (event.user) {
        delete event.user.ip_address;
      }

      // Remove IP from request
      if (event.request) {
        delete event.request.headers['Remote-Address'];
        delete event.request.headers['remote-addr'];
        delete event.request.headers['X-Real-IP'];
        delete event.request.cookies;
      }

      // Apply our comprehensive sanitization
      return sanitizeSentryEvent(event);
    },

    initialScope: {
      user: {}, // Empty user object
    },

    enableTracing: true,
    autoSessionTracking: true,
  });

  // Set empty user data
  Sentry.setUser({});
}

const originalFetch = window.fetch;
window.fetch = async (...args) => {
  try {
    const response = await originalFetch(...args);
    if (!response.ok) {
      // Expected HTTP errors (like 404) - maybe log but don't send to Sentry
      console.warn(`HTTP error: ${response.status} for ${args[0]}`);
      // Only capture unexpected server errors
      if (response.status >= 500) {
        Sentry.captureException(new Error(`Server error ${response.status}`));
      }
    }
    return response;
  } catch (error) {
    // Unexpected network errors - worth capturing
    const enhancedError = new Error(`Network error: ${error.message}`);
    enhancedError.originalError = error;
    Sentry.captureException(enhancedError);
    throw error;
  }
};

if (process.env.REACT_APP_ENV === 'development') {
  window.document.addEventListener(
    'parentEvent',
    (e2) => {
      console.log(e2.detail);
    },
    false
  );

  const data2 = { esimerkkidata: 'tämä on esimerkkidatan sisältö' };
  window.event2 = new CustomEvent('iframeEvent', { detail: data2 });

  // Add button programmatically
  const button = document.createElement('span');
  button.id = 'data-button';
  button.onclick = () => window.parent.document.dispatchEvent(window.event2);
  button.textContent = 'Lähetä dataa parent-sivulle';
  document.body.appendChild(button);
}

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
root.render(
  <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>} showDialog>
    <App />
  </Sentry.ErrorBoundary>
);

// Modified reportWebVitals to use existing GA instance
reportWebVitals(({ name, value }) => {
  if (useAnalytics) {
    // Send the web vitals data using the existing GA instance
    ReactGA.event({
      category: 'Web Vitals',
      action: name,
      value: Math.round(value), // Google Analytics requires integer values
      nonInteraction: true,
    });
  }
});
