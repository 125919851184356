const SENSITIVE_FIELDS = [
  'age',
  'gender',
  'message',
  'content',
  'freeText',
  'additionalInfo',
  'consent',
  'Authorization',
  'token',
  'sessionId',
];

const sanitizeValue = (value) => {
  if (typeof value === 'string') return '[REDACTED]';
  if (typeof value === 'number') return 0;
  if (typeof value === 'boolean') return false;
  return null;
};

const sanitizeData = (data, fields = SENSITIVE_FIELDS) => {
  if (!data || typeof data !== 'object') return data;

  const sanitized = Array.isArray(data) ? [...data] : { ...data };

  for (const key in sanitized) {
    if (fields.includes(key)) {
      sanitized[key] = sanitizeValue(sanitized[key]);
    } else if (typeof sanitized[key] === 'object') {
      sanitized[key] = sanitizeData(sanitized[key], fields);
    }
  }

  return sanitized;
};

export const sanitizeSentryEvent = (event) => {
  // Don't modify if no event
  if (!event) return event;

  // Deep clone the event to avoid modifying the original
  const sanitizedEvent = JSON.parse(JSON.stringify(event));

  // Sanitize request data
  if (sanitizedEvent.request) {
    // Sanitize query string
    if (sanitizedEvent.request.query_string) {
      sanitizedEvent.request.query_string = '[REDACTED]';
    }

    // Sanitize request body
    if (sanitizedEvent.request.data) {
      sanitizedEvent.request.data = sanitizeData(sanitizedEvent.request.data);
    }

    // Sanitize headers
    if (sanitizedEvent.request.headers) {
      sanitizedEvent.request.headers = sanitizeData(sanitizedEvent.request.headers);
    }
  }

  // Safely sanitize breadcrumbs
  if (
    sanitizedEvent.breadcrumbs &&
    sanitizedEvent.breadcrumbs.values &&
    Array.isArray(sanitizedEvent.breadcrumbs.values)
  ) {
    sanitizedEvent.breadcrumbs.values = sanitizedEvent.breadcrumbs.values.map((breadcrumb) => {
      if (breadcrumb && breadcrumb.data) {
        return {
          ...breadcrumb,
          data: sanitizeData(breadcrumb.data),
        };
      }
      return breadcrumb;
    });
  }

  // Sanitize extra context
  if (sanitizedEvent.extra) {
    sanitizedEvent.extra = sanitizeData(sanitizedEvent.extra);
  }

  // Sanitize contexts
  if (sanitizedEvent.contexts) {
    sanitizedEvent.contexts = sanitizeData(sanitizedEvent.contexts);
  }

  return sanitizedEvent;
};
